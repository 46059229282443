import { web3Accounts, web3Enable } from "@polkadot/extension-dapp";
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import APIContext from "./api-context";
import earlyAddresses from "../data/early-addresses.json";
import { encodeAddress, decodeAddress } from "@polkadot/keyring";

export interface Account {
  name: string;
  balance: string;
  address: string;
  isEarlyContributor: boolean;
}

interface UserDetails {
  currentAccount: Account;
  accounts: Account[];
}

interface UserContextProps extends UserDetails {
  onConnect: () => void;
  onChangeAccount: (address: string) => void;
  onContribute: () => void;
  contribution: string;
}

const UserContext = createContext<Partial<UserContextProps>>({
  contribution: "0",
});

const PARACHAIN_ID = process.env.REACT_APP_PARACHAIN_ID;

export const UserContextProvider: FC = (props) => {
  const { api, setSigner } = useContext(APIContext);
  const [accounts, setAccounts] = useState<Account[]>();
  const [currentAccount, setCurrentAccount] = useState<Account>();
  const [contribution, setContribution] = useState("0");

  useEffect(() => {
    if (currentAccount === undefined) return;
    fetchContributions(currentAccount.address);
    // const intervalRef = setInterval(() => {
    //   fetchContributions(currentAccount.address);
    // }, 10000);
    // return () => clearInterval(intervalRef);
  }, [currentAccount]);

  const handleConnect = async () => {
    if (!api || !setSigner) return;

    await web3Enable("Zeitgeist Parachain auction");
    const accounts = await web3Accounts({ ss58Format: 2 });

    const balances = await Promise.all(
      accounts.map((account) => api.query.system.account(account.address))
    );

    const accountDetails: Account[] = accounts.map((account, index) => {
      const subtrateAddress = encodeAddress(decodeAddress(account.address));

      return {
        name: account.meta.name ?? `Account ${index}`,
        balance: (
          (balances[index].data.free.toNumber() -
            balances[index].data.feeFrozen.toNumber()) /
          10 ** 12
        ).toFixed(4),
        address: account.address,
        isEarlyContributor: earlyAddresses.some(
          (address) => address.toLowerCase() === subtrateAddress.toLowerCase()
        ),
      };
    });

    setAccounts(accountDetails);
    setCurrentAccount(accountDetails[0]);
    setSigner(accountDetails[0].address);
  };

  const handleChangeAccount = (address: string) => {
    if (!accounts) return;
    const newAccount = accounts.find((account) => account.address === address);
    if (newAccount) {
      setCurrentAccount(newAccount);
      if (setSigner) {
        setSigner(newAccount.address);
      }
    }
  };

  const fetchContributions = async (address: string) => {
    const url = "https://api.subquery.network/sq/subvis-io/kusama-auction";
    //TODO: Endpoint doesn't allow filtering for auction Id, for future auctions we will need to
    // filter out previous contributions
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `query {contributions (filter: {account: {equalToInsensitive: "${address}"}}) {nodes {    id    amount    parachainId    blockNum} totalCount}}`,
      }),
    });
    const json = await response.json();
    const contributions = json.data.contributions.nodes.filter(
      (node: any) => node.parachainId === PARACHAIN_ID
    );
    const contributionTotal = contributions.reduce(
      (acc: number, con: any) => acc + Number(con.amount),
      0
    );
    setContribution((contributionTotal / 10 ** 12).toFixed(2));
  };

  const handleContribute = () => {
    if (currentAccount) {
      fetchContributions(currentAccount?.address);
    }
  };

  return (
    <UserContext.Provider
      value={{
        accounts: accounts,
        currentAccount: currentAccount,
        contribution: contribution,
        onConnect: handleConnect,
        onContribute: handleContribute,
        onChangeAccount: handleChangeAccount,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
