import { useContext } from "react";
import APIContext from "../../store/api-context";
import UserContext from "../../store/user-context";

const WalletConnect = () => {
  const { onConnect } = useContext(UserContext);
  const { api } = useContext(APIContext);
  const handleConnect = async () => {
    if (onConnect) {
      onConnect();
    }
  };

  return (
    <button
      className="bg-black border border-white text-center w-full rounded-md font-bold disabled:opacity-20 disabled:cursor-default"
      onClick={handleConnect}
      style={{ height: "40px" }}
      disabled={!api}
    >
      Connect Wallet
    </button>
  );
};
export default WalletConnect;
