import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { APIContextProvider } from "./store/api-context";
import { CrowdloanContextProvider } from "./store/crowdloan-context";
import { UserContextProvider } from "./store/user-context";
import { CompetitorContextProvider } from "./store/competitor-context";
import { Link, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <APIContextProvider>
      <UserContextProvider>
        <CrowdloanContextProvider>
          <CompetitorContextProvider>
            <Router>
              <Switch>
                <Route path="" children={<App />}></Route>
              </Switch>
            </Router>
          </CompetitorContextProvider>
        </CrowdloanContextProvider>
      </UserContextProvider>
    </APIContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
