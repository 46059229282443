import { ChangeEvent, useContext, useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";
import APIContext from "../../store/api-context";
import CrowdloanContext from "../../store/crowdloan-context";
import UserContext from "../../store/user-context";
import Slider from "../ui/Slider";
import AccountSelect from "./AccountSelect";
import WalletConnect from "./WalletConnect";
import { useLocation } from "react-router-dom";
import { TwitterIcon, TwitterShareButton } from "react-share";
import Checkbox from "../ui/Checkbox";

interface ReadonlyFieldProps {
  title: string;
  value: string;
}

const ReadOnlyField = ({ title, value }: ReadonlyFieldProps) => {
  return (
    <div className="bg-black rounded-md border-light-grey border flex items-center h-10 p-2 ">
      <div className="text-xs font-bold text-light-grey">{title}</div>
      <div className="ml-auto font-mono text-light-grey-2 text-sm">{value}</div>
    </div>
  );
};

const TOTAL_ZTG_REWARD = Number(process.env.REACT_APP_ZTG_REWARD);
const PARA_ID = Number(process.env.REACT_APP_PARA_ID);
const TERMS_LINK = process.env.REACT_APP_TERMS_LINK;
// const APP_URL = process.env.REACT_APP_VERCEL_URL ?? "crowdloan.zeitgeist.pm";
const APP_URL = "crowdloan.zeitgeist.pm";

const Contribute = () => {
  const { api } = useContext(APIContext);
  const { accounts, currentAccount, onContribute } = useContext(UserContext);
  const { ksmCap, ksmRaised } = useContext(CrowdloanContext);
  const [sliderValue, setSliderValue] = useState(0);
  const [amount, setAmount] = useState<string>("");
  const [crowdloanShare, setCrowdloanShare] = useState<string>("0");
  const [minReward, setMinReward] = useState<string>("0");
  const [referralCode, setReferralCode] = useState<string>("");
  const [signupCode, setSignupCode] = useState<string>("");
  const [estimatedTxFee, setEstimatedTxFee] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const urlCode = location.pathname.split("/referral=")[1];
    if (urlCode) {
      setReferralCode(urlCode);
    }
  }, []);

  useEffect(() => {
    if (!api || !currentAccount) return;
    (async () => {
      const paymentInfo = await api.tx.utility
        .batchAll([
          api.tx.crowdloan.contribute(PARA_ID, 1 * 10 ** 12, null),
          api.tx.system.remark(
            `RGRaNVRCTUJtOVVzQW9uelB1eXNSRnhtMTlMbzR2Mm9jVFNpcVV1ZTdrZEpFTVU=-RGRaNVRCTUJtOVVzQW9uelB1eXNSRnhtMTlMbzR2Mm9jVFNpcVV1ZTdrZEpFTVU=-I have read and agree to the terms found at ${TERMS_LINK}`
          ),
        ])
        .paymentInfo(currentAccount.address);

      const fee = parseFloat(
        ((paymentInfo.partialFee.toNumber() / 10 ** 12) * 1.1).toFixed(7)
      );
      setEstimatedTxFee(fee);
    })();
  }, [api, currentAccount]);

  useEffect(() => {
    setAmount("");
    setSliderValue(0);
  }, [currentAccount?.address]);

  useEffect(() => {
    if (
      (amount !== undefined || amount !== "") &&
      ksmRaised !== undefined &&
      ksmCap !== undefined
    ) {
      const shareOfCurrentBid =
        Number(ksmRaised) === 0 ? 1 : Number(amount) / Number(ksmRaised);
      setCrowdloanShare((shareOfCurrentBid * 100).toFixed(5));

      const shareOfKSMCap = Number(amount) / ksmCap;
      const minZtgReward = shareOfKSMCap * TOTAL_ZTG_REWARD;
      setMinReward(minZtgReward.toFixed(1));
    }
  }, [amount, ksmCap, ksmRaised]);

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
    if (!currentAccount) return;

    const maxBalance = Number(currentAccount.balance) - estimatedTxFee;
    if (maxBalance > 0) {
      const newAmount = ((maxBalance * value) / 100).toFixed(4);
      setAmount(newAmount);
    }
  };

  const recalcuateSlider = (newAmount: string) => {
    if (!currentAccount) return;
    const maxBalance = Number(currentAccount.balance) - estimatedTxFee;
    if (maxBalance > 0 && newAmount != null) {
      const sliderValue = Math.round((Number(newAmount) / maxBalance) * 100);
      setSliderValue(sliderValue);
    }
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
    recalcuateSlider(event.target.value);
  };

  const handleReferralCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReferralCode(event.target.value);
  };

  const handleSignupCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSignupCode(event.target.value);
  };

  const handleMAXClick = () => {
    if (currentAccount && parseFloat(currentAccount?.balance) !== 0) {
      const maxBalance = parseFloat(currentAccount?.balance) - estimatedTxFee;
      setAmount(maxBalance.toString());
      recalcuateSlider(maxBalance.toString());
    } else {
      setAmount("0");
    }
  };

  const handleContributeClick = async () => {
    if (!api || !currentAccount) return;

    setIsLoading(true);
    let success = false;
    api.tx.utility
      .batchAll([
        api.tx.crowdloan.contribute(PARA_ID, Number(amount) * 10 ** 12, null),
        api.tx.system.remark(
          `${referralCode}-${signupCode}-I have read and agree to the terms found at ${TERMS_LINK}`
        ),
      ])
      .signAndSend(currentAccount.address, (result) => {
        const { status, events } = result;

        if (status.isInBlock) {
          for (const event of events) {
            const { method } = event.event;
            if (method === "ExtrinsicSuccess") {
              success = true;
              console.log("success");
            }
          }
        } else if (status.isFinalized) {
          if (onContribute) {
            //wait for subvis to sync
            setTimeout(() => {
              setIsLoading(false);
              if (success === true) {
                setCompleted(true);
              }
              onContribute();
            }, 3000);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  return (
    <div className="hidden sm:flex flex-col w-1/2 md:5/12 lg:w-4/12 ">
      <div className="font-kanit font-bold text-xl mb-4">
        Contribute with Kusama
      </div>
      {completed === true ? (
        <div
          className="bg-black rounded-lg flex flex-col items-center justify-center gap-y-4 font-bold px-4 text-center"
          style={{ height: "535px" }}
        >
          <div>Thanks for contributing {amount} KSM to our Crowdloan!</div>
          <div>
            Share your referral code for an additional 5% bonus for both you and
            your friend.
          </div>
          <TwitterShareButton
            url={`https://${APP_URL}/referral=${
              currentAccount ? btoa(currentAccount?.address) : ""
            }`}
            title={`I'm proud to contribute to the @ZeitgeistPM Crowdloan. 🚀

If you're keen on contributing too, use my referral code for a 5% bonus:`}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
      ) : (
        <div className="bg-black rounded-lg flex flex-col px-4 gap-y-4 py-4">
          <div>
            <div className="font-bold text-xs mb-1">Select Account</div>
            {accounts ? <AccountSelect /> : <WalletConnect />}
          </div>

          <div className="bg-dark-grey-2 rounded-md flex items-center p-2 h-10">
            <svg
              width="23"
              height="15"
              viewBox="0 0 23 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.7005 0.778891C21.2693 0.395659 20.7903 0.108236 20.2154 0.0124282C19.4969 -0.0833797 18.7783 0.395659 18.2993 0.730987C17.8203 1.06631 16.8622 1.92858 16.4789 2.26391C16.0957 2.59924 15.1376 2.79086 13.5568 3.74893C11.976 4.70701 5.84428 8.63513 5.84428 8.63513H7.42511L0.287419 12.3237H1.00598L0 13.0902C0.574847 13.2339 1.14969 13.1381 1.67664 12.8507V13.0902C1.67664 13.0902 10.1556 9.73692 11.7844 10.5992L10.7784 10.8866C10.8742 10.8866 12.455 10.9824 12.455 10.9824C12.5508 11.6531 12.9341 12.2758 13.5089 12.6112C14.467 13.2339 14.5149 13.6171 14.5149 13.6171C14.5149 13.6171 14.0358 13.8088 14.0358 14.0962C14.946 13.8088 15.9041 13.8088 16.7664 14.0962C16.7664 14.0962 16.7185 13.8088 16.0478 13.6171C15.3771 13.3297 14.8023 12.8986 14.3712 12.2758C13.9879 11.7489 13.8442 11.0782 14.0838 10.4555C14.3233 9.83273 15.1376 9.4974 16.8143 8.63513C18.7783 7.62915 19.2574 6.86269 19.4969 6.23994C19.7364 5.61719 20.2154 4.46749 20.4071 3.89265C20.5987 3.3657 20.934 2.93457 21.3651 2.55134C21.8921 2.26391 22.4669 2.02439 22.9939 1.83278C23.0897 1.88068 22.0358 1.01841 21.7005 0.778891Z"
                fill="#B0BDC7"
              />
            </svg>

            <button
              className="text-light-grey-2 ml-2 text-xs"
              onClick={handleMAXClick}
            >
              MAX
            </button>
            <input
              className="ml-auto text-xs font-bold text-light-grey bg-dark-grey-2 focus:outline-none focus:text-white text-right"
              value={amount}
              onChange={handleAmountChange}
              type="number"
            />
          </div>
          <ReadOnlyField
            title="Minimum ZTG rewards"
            value={`${minReward} ZTG`}
          />
          <ReadOnlyField title="Crowdloan Share" value={`${crowdloanShare}%`} />

          <input
            className="bg-dark-grey-2 rounded-md flex items-center p-2 h-10 text-xs font-bold text-light-grey focus:outline-none focus:text-white"
            placeholder="Early Sign up Code"
            value={signupCode}
            onChange={handleSignupCodeChange}
            type="text"
            spellCheck="false"
          />
          <input
            className="bg-dark-grey-2 rounded-md flex items-center p-2 h-10 text-xs font-bold text-light-grey focus:outline-none focus:text-white"
            placeholder="Referral Code"
            value={referralCode}
            onChange={handleReferralCodeChange}
            type="text"
            spellCheck="false"
          />

          <Slider value={sliderValue} onChange={handleSliderChange} />
          <div className="flex">
            <Checkbox value={agreed} onChange={() => setAgreed(!agreed)} />
            <div className="text-xs text-light-grey">
              I agree to the the{" "}
              <a
                href={TERMS_LINK}
                target="_blank"
                rel="noreferrer"
                className="text-white cursor-pointer"
              >
                Terms and Conditions
              </a>{" "}
              for the Zeitgeist Kusama Parachain Slot Campaign
            </div>
          </div>
          <button
            onClick={handleContributeClick}
            className="bg-ztg-blue text-white focus:outline-none disabled:opacity-20 disabled:cursor-default 
                rounded-full w-full font-kanit font-bold text-ztg-16-150 h-10 flex items-center justify-center"
            disabled={
              !currentAccount ||
              !amount ||
              amount === "" ||
              parseFloat(amount) === 0 ||
              isLoading === true ||
              agreed === false
            }
            style={
              isLoading === true
                ? {
                    opacity: 1,
                  }
                : {}
            }
          >
            {isLoading === true ? (
              <div className="mb-3">
                <PropagateLoader loading={isLoading} color={"white"} />
              </div>
            ) : (
              "Contribute"
            )}
          </button>
        </div>
      )}
      <div
        className="border border-light-grey text-light-grey rounded-md mt-4 flex items-center justify-center px-4"
        style={{ height: "70px" }}
      >
        <div>
          See list of contributors on{" "}
          <a
            href="https://kusama.subvis.io/crowdloan/2101-CsCM5QH1CDq2HEr7pofxofxoxbrmHSEHcHkCJsLJ7zMs1iD-0"
            target="_blank"
            rel="noreferrer"
            className="underline font-bold text-white"
          >
            subvis.io
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contribute;
