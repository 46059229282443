import { useState } from "react";
import { Menu, X } from "react-feather";

const Logo = () => {
  return (
    <div className="font-kanit font-bold text-white text-sm flex items-center">
      <svg
        className="ml-8"
        width="38"
        height="38"
        viewBox="0 0 74 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.7204 0.489764C28.1574 0.47586 19.864 3.48298 13.2998 8.98179H60.1348C53.5737 3.48202 45.2817 0.474671 36.7204 0.489764ZM36.7204 73.3469C45.2025 73.3626 53.4228 70.4105 59.9569 65.0021H13.4778C20.0148 70.4096 28.2367 73.3615 36.7204 73.3469ZM54.1094 18.9832H68.4796C67.2958 16.8976 65.9107 14.933 64.344 13.1174H9.12133C6.81724 15.7728 4.91155 18.7491 3.46407 21.953H51.9925L54.1094 18.9832ZM36.3707 39.0597H0.291838C0.464868 42.061 1.01107 45.0291 1.91784 47.8954H32.9959L35.1374 44.8827H72.3268C72.7555 42.9669 73.0285 41.0196 73.1428 39.0597H36.3707ZM44.4884 31.9299H72.8667C72.5983 29.9416 72.1631 27.9794 71.5659 26.064H1.86876C0.976936 28.9322 0.445173 31.9002 0.285706 34.8996H42.3777L44.4884 31.9299ZM9.22564 60.8666H23.6756L25.7863 57.8968H66.5468C67.8552 56.0519 68.9872 54.088 69.9277 52.031H3.51316C4.97873 55.2376 6.90305 58.214 9.22564 60.8666Z"
          fill="#ffffff"
        />
      </svg>

      <span className="text-xl ml-4">Zeitgeist</span>
    </div>
  );
};

const Links = () => {
  return (
    <>
      <a href="https://zeitgeist.pm" target="_blank" rel="noreferrer">
        Home
      </a>
      <a
        href="https://discord.com/invite/xv8HuA4s8v"
        target="_blank"
        rel="noreferrer"
      >
        Discord
      </a>
      <a
        href="https://twitter.com/ZeitgeistPM"
        target="_blank"
        rel="noreferrer"
      >
        Twitter
      </a>
      <a href="https://github.com/ZeitgeistPM" target="_blank" rel="noreferrer">
        GitHub
      </a>
      <a href="https://beta.zeitgeist.pm/" target="_blank" rel="noreferrer">
        App <span className="text-ztg-blue">/Beta/</span>
      </a>
      <a href="https://blog.zeitgeist.pm/" target="_blank" rel="noreferrer">
        Blog
      </a>
    </>
  );
};

const Header = () => {
  const [showMenuOverlay, setShowMenuOverlay] = useState(false);

  const toggleMenu = () => {
    if (showMenuOverlay === true) {
      setShowMenuOverlay(false);
    } else {
      setShowMenuOverlay(true);
    }
  };

  return (
    <>
      <header
        className="bg-black w-full flex items-center"
        style={{ height: "76px", minHeight: "76px" }}
      >
        <Logo />
        <div
          className="hidden md:flex font-kanit font-bold text-sm ml-auto justify-around "
          style={{ width: "80%" }}
        >
          <Links />
        </div>
        <Menu className="md:hidden ml-auto mr-10" onClick={toggleMenu} />
      </header>
      {showMenuOverlay === true ? (
        <div
          className="h-full w-screen absolute flex flex-col z-10"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 1, 254, 0) 0%, #0001FE 189.24%),linear-gradient(0deg, #000000, #000000)",
          }}
        >
          <div
            className="flex items-center"
            style={{ height: "76px", minHeight: "76px" }}
          >
            <Logo />
            <X className="ml-auto mr-8" onClick={toggleMenu} />
          </div>
          <div className="flex flex-col items-center w-full gap-5 mt-10">
            <Links />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
