import Chart from "./Chart";
import Contribute from "./Contribute";
import Stats from "./Stats";

const MainContent = () => {
  return (
    <main
      className="px-6 md:px-16 lg:px-36 h-full pt-6"
      style={
        {
          // height: "calc(100% - 136px)",
        }
      }
    >
      <Stats />
      <div className="flex gap-x-4">
        <Chart />
        <Contribute />
      </div>
    </main>
  );
};

export default MainContent;
