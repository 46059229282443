import { useContext } from "react";
import CrowdloanContext from "../../store/crowdloan-context";
import UserContext from "../../store/user-context";

interface StatCardProps {
  title: string;
  body: string;
}

const StatCard = ({ title, body }: StatCardProps) => {
  return (
    <div className="bg-black rounded-xl flex justify-between w-full p-4">
      <div
        className="bg-dark-grey rounded-full px-1.5 py-1 text-xs"
        style={{ width: "fit-content" }}
      >
        {title}
      </div>
      <div className="font-mono font-bold text-base pl-1">{body}</div>
    </div>
  );
};

const TOTAL_ZTG_REWARD = Number(process.env.REACT_APP_ZTG_REWARD);

const Stats = () => {
  const { contribution } = useContext(UserContext);
  const { ksmCap } = useContext(CrowdloanContext);
  let minZtgReward = 0;
  if (ksmCap) {
    const shareOfKSMCap = Number(contribution ?? 0) / ksmCap;
    minZtgReward = shareOfKSMCap * TOTAL_ZTG_REWARD;
  }

  return (
    <>
      <div className="hidden sm:flex gap-x-5 mb-6">
        <StatCard
          title="Your Contribution"
          body={`${contribution ?? "0"} KSM`}
        />
        <StatCard
          title="Your Minimum Reward"
          body={`${minZtgReward.toFixed(0)} ZTG`}
        />
      </div>
      <div className="font-kanit font-bold text-xl sm:hidden text-center mb-6 text-laser-lemon">
        Please use Desktop app to contribute
      </div>
    </>
  );
};

export default Stats;
