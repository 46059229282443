import { Decimal } from "decimal.js";
// import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";

interface TimeSeriesChartProps {
  data: ChartData[];
  series: ChartSeries[];
  xDomain?: AxisDomain;
  referenceLineXPos?: number;
}

export interface ChartSeries {
  accessor: string;
  label: string;
  color?: string;
  lineWidth: number;
}

export interface ChartData {
  t: number;
  [key: string]: number;
}

const ChartToolTip = (props: any) => {
  return (
    <>
      {props.label !== undefined &&
      props.label !== -Infinity &&
      props.label !== Infinity ? (
        <div
          className="px-1 py-2 bg-black rounded-1"
          style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="font-bold text-sm">
            <span>
              {new Intl.DateTimeFormat("default", {
                dateStyle: "short",
              }).format(new Date(props.label))}
            </span>
            <span className="text-wh9te ml-4">
              {new Intl.DateTimeFormat("default", {
                hour: "numeric",
                minute: "numeric",
              }).format(new Date(props.label))}
            </span>
            <div className="mt-3">
              {props.payload.map((line: any, index: number) => {
                return (
                  <div className="flex" key={index}>
                    <span style={{ color: line.stroke }}>
                      {props.series[index].label}
                    </span>
                    <span className="ml-auto">
                      {new Decimal(line.value / 10000000000).toFixed(3) +
                        " KSM"}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const TimeSeriesChart = ({
  data,
  series,
  xDomain,
  referenceLineXPos,
}: TimeSeriesChartProps) => {
  const [mouseInside, setMouseInside] = useState(false);

  const handleMouseEnter = () => {
    setMouseInside(true);
  };

  const handleMouseLeave = () => {
    setMouseInside(false);
  };

  return (
    <div
      className="p-2"
      style={{ width: "100%", height: "100%" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {data?.length > 0 ? (
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={data}
            // margin={{
            //   top: 20,
            //   right: 30,
            //   left: 70,
            //   bottom: 50,
            // }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              strokeWidth={0.2}
              stroke="#748296"
            />
            <XAxis
              dataKey="t"
              domain={xDomain ?? ["dataMin", "dataMax"]}
              tickCount={5}
              tick={{ fontFamily: "Roboto Mono", fontSize: "10px" }}
              type="number"
              stroke="#748296"
              tickLine={false}
              strokeWidth={0.7}
              tickFormatter={(unixTime) => {
                if (unixTime !== -Infinity && unixTime !== Infinity) {
                  return new Intl.DateTimeFormat("defualt", {
                    dateStyle: "short",
                    // timeStyle: "short",
                  }).format(new Date(unixTime));
                } else {
                  return "";
                }
              }}
            />
            <YAxis
              tick={{
                fontFamily: "Roboto Mono",
                fontSize: "10px",
              }}
              tickLine={false}
              domain={[
                0,
                (datamax: number) =>
                  Math.round((datamax + 10000) / 10000) * 10000,
              ]}
              stroke="#748296"
              strokeWidth={0.7}
              tickFormatter={(val) => val + " KSM"}
            />
            {/* {series ? ( */}
            <Legend
              verticalAlign="bottom"
              align="right"
              height={36}
              iconType="circle"
              iconSize={10}
            />
            {/* ) : (
              <></>
            )} */}

            {/* <Tooltip
              animationEasing={"linear"}
              animationDuration={0}
              content={<ChartToolTip series={series} />}
            /> */}

            {series.map((s, index) => (
              <Line
                // isAnimationActive={false}
                name={s.label}
                key={index}
                strokeWidth={mouseInside ? s.lineWidth : s.lineWidth - 1}
                type="monotone"
                dataKey={s.accessor}
                dot={false}
                stroke={s.color ? s.color : "#0001FE"}
              />
            ))}

            {referenceLineXPos ? (
              <ReferenceLine x={referenceLineXPos} stroke="green" />
            ) : (
              <></>
            )}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        //   <Skeleton
        //     className="ml-ztg-20 rounded-ztg-5"
        //     animation="wave"
        //     height={350}
        //     variant="rect"
        //   />
        <></>
      )}
    </div>
  );
};
export default TimeSeriesChart;
