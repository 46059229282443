import { useContext, useEffect, useState } from "react";
import UserContext from "../../store/user-context";
import { Copy, X } from "react-feather";

const GenerateCode = () => {
  const { currentAccount } = useContext(UserContext);
  const [buttonText, setButtonText] = useState("Connect Wallet");
  const [code, setCode] = useState<string>();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (currentAccount !== undefined) {
      setButtonText("Generate Code");
    } else {
      setButtonText("Connect Wallet");
    }
    setCode(undefined);
  }, [currentAccount]);

  const handleGenerateClick = () => {
    if (currentAccount !== undefined) {
      const base64Address = btoa(currentAccount.address);
      setCode(base64Address);
    }
  };

  const handleCopy = () => {
    if (code) {
      navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  const handleClear = () => {
    setCode(undefined);
  };

  return (
    <>
      {code ? (
        <>
          <div className="w-1/2 font-bold font-kanit border-2 border-ztg-blue rounded-md py-1 flex items-center justify-center px-3 ">
            {copied === true ? "Copied!" : <div>{code.substr(0, 7)}...</div>}

            <Copy
              size={20}
              className="ml-4 mr-1 cursor-pointer"
              onClick={handleCopy}
            />
            <X className="cursor-pointer" size={20} onClick={handleClear} />
          </div>
        </>
      ) : (
        <button
          className="w-1/2 font-bold font-kanit border-2 border-ztg-blue rounded-md py-1 disabled:opacity-20"
          onClick={handleGenerateClick}
          disabled={!currentAccount}
        >
          {buttonText}
        </button>
      )}
    </>
  );
};

export default GenerateCode;
