import React, { createContext, FC, useEffect, useState } from "react";
import { ChartData } from "../components/ui/TimeSeriesChart";
import getContributionHistory from "../utils/get-contribution-history";

interface Contribution {
  amount: string;
  createdAt: string;
}

interface CrowdloanContextProps {
  accContributions: ChartData[];
  contributions: Contribution[];
  ksmCap: number;
  ksmRaised: string;
  isFinished: boolean;
}

const PARACHAIN_ID = process.env.REACT_APP_PARACHAIN_ID;

const CrowdloanContext = createContext<Partial<CrowdloanContextProps>>({});

export const CrowdloanContextProvider: FC = (props) => {
  const [accContributions, setAccContributions] = useState<ChartData[]>([]);
  const [crowdloanDetails, setCrowdloanDetails] =
    useState<Partial<CrowdloanContextProps>>();

  useEffect(() => {
    if (!PARACHAIN_ID) return;
    const url = "https://api.subquery.network/sq/subvis-io/kusama-auction";
    (async () => {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `query {\n    crowdloans (filter: {\n      \n      parachainId: {\n        equalTo: "${PARACHAIN_ID}"      } \n    }) {\n        nodes {\n            id\n            raised\n          isFinished\n          cap\n            parachainId\n        }\n    }\n}`,
        }),
      });
      const json = await response.json();
      const crowdloan = json.data.crowdloans.nodes[0];

      setCrowdloanDetails({
        ksmCap: Number(crowdloan.cap) / 10 ** 12,
        ksmRaised: (Number(crowdloan.raised) / 10 ** 12).toFixed(1),
        isFinished: crowdloan.isFinished,
      });

      const chartData = await getContributionHistory(PARACHAIN_ID);
      setAccContributions(chartData);
    })();

    // incase subvis goes down
    // setCrowdloanDetails({
    //   ksmCap: 125000,
    //   ksmRaised: "0",
    //   isFinished: false,
    // });
  }, []);

  return (
    <CrowdloanContext.Provider
      value={{ ...crowdloanDetails, accContributions: accContributions }}
    >
      {props.children}
    </CrowdloanContext.Provider>
  );
};

export default CrowdloanContext;
