import { ChartData } from "../components/ui/TimeSeriesChart";

const url = "https://api.subquery.network/sq/subvis-io/kusama-auction";

interface Contribution {
  amount: string;
  createdAt: string;
}

const getContributionHistory = async (parachainId: string) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `query {\n    crowdloans (filter: {\n      \n      parachainId: {\n        equalTo: "${parachainId}"      } \n    }) {\n        nodes {\n            id\n            raised\n          isFinished\n          cap\n            parachainId\n          contributions (orderBy: CREATED_AT_ASC) {\n            nodes {amount createdAt}\n          }\n        }\n    }\n}`,
    }),
  });
  const json = await response.json();
  //Get the most recent crowdloan
  const crowdloan =
    json.data.crowdloans.nodes[json.data.crowdloans.nodes.length - 1];
  const contributions: Contribution[] = crowdloan.contributions.nodes;

  if (contributions.length > 0) {
    const accumulatedContributions: ChartData[] = [
      {
        t: new Date(contributions[0].createdAt).getTime(),
        v: Number(contributions[0].amount) / 10 ** 12,
      },
    ];

    //Add up all contribution history to display accumulation on the chart
    contributions.forEach((c, i) => {
      if (i !== 0) {
        const previous =
          accumulatedContributions[accumulatedContributions.length - 1];
        const unixTime = new Date(c.createdAt).getTime();

        if (previous.t === unixTime) {
          previous.v = previous.v + Number(c.amount) / 10 ** 12;
        } else {
          accumulatedContributions.push({
            t: unixTime,
            v: previous.v + Number(c.amount) / 10 ** 12,
          });
        }
      }
    });
    return accumulatedContributions;
  } else {
    return [];
  }
};

export default getContributionHistory;
