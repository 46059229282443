import { ApiPromise, WsProvider } from "@polkadot/api";
import { web3FromAddress } from "@polkadot/extension-dapp";
import React, { createContext, FC, useEffect, useState } from "react";

interface APIContextProps {
  api: ApiPromise;
  setSigner: (address: string) => void;
}

const APIContext = createContext<Partial<APIContextProps>>({});

export const APIContextProvider: FC = (props) => {
  const [api, setApi] = useState<ApiPromise>();

  useEffect(() => {
    (async () => {
      const provider = new WsProvider("wss://kusama-rpc.polkadot.io");
      const api = await ApiPromise.create({ provider });
      setApi(api);
    })();
  }, []);

  const setSigner = async (address: string) => {
    if (api) {
      const injector = await web3FromAddress(address);
      const newAPI = api;
      await newAPI.setSigner(injector.signer);
      setApi(newAPI);
    }
  };

  return (
    <APIContext.Provider value={{ api: api, setSigner: setSigner }}>
      {props.children}
    </APIContext.Provider>
  );
};

export default APIContext;
