const Footer = () => {
  return (
    <footer className="flex">
      <div className="text-xs mt-auto px-6 md:px-16 lg:px-36 py-3 ml-auto">
        © 2021 Zeitgeist PM
      </div>
    </footer>
  );
};

export default Footer;
