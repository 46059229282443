import { useContext } from "react";
import Select, { GroupBase, SingleValue, StylesConfig } from "react-select";
import UserContext, { Account } from "../../store/user-context";

const customStyles: StylesConfig<Account, false, GroupBase<Account>> = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "black",
      color: "#748296",
    },
    backgroundColor: state.isSelected ? "#0001FE" : "#11161F",
    color: state.isSelected ? "white" : "#748296",
  }),
  control: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    fontSize: "14px",
    color: "#748296",
    backgroundColor: "#11161F",
    height: 40,
    border: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    fontSize: "14px",
    color: "#748296",
    backgroundColor: "dark",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    fontSize: "14px",
    color: "#748296",
    backgroundColor: "#11161F",
  }),
  dropdownIndicator: () => ({
    color: "#748296",
    margin: "0 10px 0 0",
  }),
};

const IndicatorSeparator = () => {
  return <></>;
};

const AccountSelect = () => {
  const { accounts, currentAccount, onChangeAccount } = useContext(UserContext);

  const handleChange = (account: SingleValue<Account>) => {
    if (onChangeAccount && account) {
      onChangeAccount(account.address);
    }
  };

  return (
    <Select<Account>
      // isSearchable={false}
      options={accounts}
      getOptionLabel={(account: Account) =>
        `${account.isEarlyContributor ? "🎁  " : ""}${account.name} - ${
          account.balance
        } KSM - ${account.address.substring(0, 10)}...`
      }
      getOptionValue={(account: Account) => account.address}
      styles={customStyles}
      value={currentAccount}
      components={{
        // Control,
        // Option,
        // SingleValue,
        // DropdownIndicator,
        IndicatorSeparator,
      }}
      onChange={(account) => handleChange(account)}
    />
  );
};

export default AccountSelect;
