import MaterialCheckBox from "@mui/material/Checkbox";
import { ChangeEvent } from "react";

interface CheckboxProps {
  value: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ value, onChange }: CheckboxProps) => {
  return (
    <MaterialCheckBox
      value={value}
      onChange={onChange}
      disableRipple={true}
      disableFocusRipple={true}
      disableTouchRipple={true}
      sx={{
        color: "#0001FE",
        "&.Mui-checked": {
          color: "#0001FE",
        },
      }}
    />
  );
};

export default Checkbox;
