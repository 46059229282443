import { useContext, useMemo } from "react";
import CompetitorContext from "../../store/competitor-context";
import CrowdloanContext from "../../store/crowdloan-context";
import TimeSeriesChart, { ChartData, ChartSeries } from "../ui/TimeSeriesChart";
import GenerateCode from "./GenerateCode";

const START_TIME_UNIX = Number(process.env.REACT_APP_AUCTION_START_UNIX);
const END_TIME_UNIX = Number(process.env.REACT_APP_AUCTION_END_UNIX);

const Chart = () => {
  const { accContributions, ksmRaised } = useContext(CrowdloanContext);
  const { contributions, name } = useContext(CompetitorContext);

  const chartData: ChartData[] = useMemo(() => {
    const ztgContributions = accContributions
      ?.map((c) => ({
        t: c.t,
        v1: c.v,
      }))
      .filter((c) => c.t > START_TIME_UNIX);
    const competitorContributions = contributions
      ?.map((c) => ({
        t: c.t,
        v2: c.v,
      }))
      .filter((c) => c.t > START_TIME_UNIX);

    // if crowdloan cap is hit ensure chart continues pushing sideways
    const now = new Date().getTime();

    if (ztgContributions && ztgContributions.length > 0) {
      ztgContributions.push({
        v1: ztgContributions[ztgContributions.length - 1].v1,
        t: now,
      });
    }

    if (competitorContributions && competitorContributions.length > 0) {
      competitorContributions.push({
        v2: competitorContributions[competitorContributions.length - 1].v2,
        t: now,
      });
    }

    return [...(ztgContributions ?? []), ...(competitorContributions ?? [])];
  }, [accContributions, contributions]);

  const chartSeries: ChartSeries[] = [
    { accessor: "v1", label: "Zeitgeist", color: "#0001FE", lineWidth: 4 },
    { accessor: "v2", label: name ?? "", color: "#F7FF58", lineWidth: 2 },
  ];
  return (
    <div className="flex flex-col w-full sm:w-1/2 md:w-7/12 lg:w-8/12  h-full ">
      <div className="font-kanit font-bold text-xl mb-4">
        Total Contribution: {ksmRaised ?? 0} KSM
      </div>
      <div className="bg-black rounded-lg" style={{ height: "535px" }}>
        <TimeSeriesChart
          series={chartSeries}
          data={chartData ?? []}
          xDomain={[START_TIME_UNIX, END_TIME_UNIX]}
          referenceLineXPos={START_TIME_UNIX + 1728 * 10 ** 5} // + 2 days
        />
      </div>
      <div className="hidden sm:flex items-center border border-light-grey text-white font-bold rounded-md px-8 py-4 mt-4">
        <div className="w-1/2">Get a 5% referral bonus on Zeitgeist</div>
        <GenerateCode />
      </div>
    </div>
  );
};

export default Chart;
