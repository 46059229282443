import MaterialSlider from "@mui/material/Slider";
import { styled } from "@mui/styles";
import { useEffect, useState } from "react";

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const shadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];

const CustomSlider = styled(MaterialSlider)(() => ({
  color: "#0001FE",
  opacity: 1,
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    border: "3px solid #748296",
    height: 17,
    width: 17,
    backgroundColor: "black",
    boxShadow: shadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      "@media (hover: none)": {
        boxShadow: shadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontFamily: "Roboto Mono",
    fontWeight: 700,
    fontSize: "10px",
    top: 0,
    backgroundColor: "unset",
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    color: "#0001FE",
  },
  "& .MuiSlider-rail": {
    height: 3,
    backgroundColor: "#2A384D",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#748296",
    height: 3,
    width: 3,
    marginTop: 0,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const Slider = ({ value, onChange, disabled = false }: SliderProps) => {
  const [sliderValue, setSliderValue] = useState<number>(() => value);

  const formatLabel = (value: number) => value + "%";

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    if (disabled || Array.isArray(newValue)) {
      return;
    }
    setSliderValue(newValue);
    onChange(newValue);
  };

  return (
    <CustomSlider
      aria-label="Slider"
      marks={marks}
      valueLabelDisplay="on"
      valueLabelFormat={formatLabel}
      value={sliderValue}
      onChange={(event, value) => handleSliderChange(event, value)}
      disabled={disabled}
    />
  );
};

export default Slider;
