import React, { useLayoutEffect, useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MainContent from "./components/main";

function App() {
  const [classes, setClasses] = useState("");

  useLayoutEffect(() => {
    const updateClasses = () => {
      if (window.innerHeight > 850) {
        setClasses("h-screen");
      } else {
        setClasses("h-full");
      }
    };

    window.addEventListener("resize", updateClasses);
    updateClasses();

    return () => window.removeEventListener("resize", updateClasses);
  }, []);

  return (
    <div
      className={`w-full bg-black flex flex-col justify-between ${classes}`}
      style={{
        background:
          "radial-gradient(104.3% 104.3% at 50% 50%, rgba(0, 1, 254, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, #000000, #000000)",
      }}
    >
      <Header />
      <MainContent />
      <Footer />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
