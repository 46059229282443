import React, { createContext, FC, useEffect, useState } from "react";
import { ChartData } from "../components/ui/TimeSeriesChart";
import getContributionHistory from "../utils/get-contribution-history";

interface CompetitorContextProps {
  contributions: ChartData[];
  name: string;
}

interface Participant {
  parachainId: string;
  name: string;
}

const CompetitorContext = createContext<Partial<CompetitorContextProps>>({});

const PARACHAIN_ID = process.env.REACT_APP_PARACHAIN_ID;
const PARTICIPANTS_JSON = process.env.REACT_APP_PARTICIPANTS_JSON ?? "[]";

export const CompetitorContextProvider: FC = (props) => {
  const [contributions, setContributions] = useState<ChartData[]>([]);
  const [competitorName, setCompetitorName] = useState<string>("");

  useEffect(() => {
    const url = "https://api.subquery.network/sq/subvis-io/kusama-auction";
    (async () => {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `query {\n    crowdloans( orderBy: RAISED_DESC\n    ) {\n      nodes {\n        parachain {\n          paraId\n        }\n        parachainId\n        raised\n        firstSlot\n      }\n    }\n}`,
        }),
      });
      const json = await response.json();
      const crowdloans = json.data.crowdloans.nodes;
      const participants: Participant[] = JSON.parse(PARTICIPANTS_JSON);

      const crowdloansInCurrentAuction = crowdloans.filter((crowdloan: any) =>
        participants
          .map((p) => p.parachainId)
          .some((id) => id === crowdloan.parachainId)
      );

      if (crowdloansInCurrentAuction.length > 1) {
        if (crowdloansInCurrentAuction[0].parachainId === PARACHAIN_ID) {
          //We are winning display second place
          const data = await getContributionHistory(
            crowdloansInCurrentAuction[1].parachainId
          );
          setContributions(data);
          const competingParticipant = participants.find(
            (p) =>
              p.parachainId.toLowerCase() ===
              crowdloansInCurrentAuction[1].parachainId.toLowerCase()
          );

          setCompetitorName(competingParticipant?.name ?? "");
        } else {
          //We're not winning, display first place
          const data = await getContributionHistory(
            crowdloansInCurrentAuction[0].parachainId
          );

          setContributions(data);

          const competingParticipant = participants.find(
            (p) =>
              p.parachainId.toLowerCase() ===
              crowdloansInCurrentAuction[0].parachainId.toLowerCase()
          );
          setCompetitorName(competingParticipant?.name ?? "");
        }
      } else {
        setContributions([]);
      }
    })();
  }, []);

  return (
    <CompetitorContext.Provider
      value={{ contributions: contributions, name: competitorName }}
    >
      {props.children}
    </CompetitorContext.Provider>
  );
};

export default CompetitorContext;
